import React, { FC } from "react";
import Card from "react-bootstrap/Card";

import { ScribbleDefaultProps } from "./ScribbleDefault";
import { makeStyles } from "./styles";

const xmur3 = (str: string) => {
    let h = 1779033703 ^ str.length;
    for (let i = 0; i < str.length; i++) {
        h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
        h = (h << 13) | (h >>> 19);
    }
    return () => {
        h = Math.imul(h ^ (h >>> 16), 2246822507);
        h = Math.imul(h ^ (h >>> 13), 3266489909);
        return (h ^= h >>> 16) >>> 0;
    };
};

const sfc32 = (a: number, b: number, c: number, d: number) => {
    return function () {
        a >>>= 0;
        b >>>= 0;
        c >>>= 0;
        d >>>= 0;
        var t = (a + b) | 0;
        a = b ^ (b >>> 9);
        b = (c + (c << 3)) | 0;
        c = (c << 21) | (c >>> 11);
        d = (d + 1) | 0;
        t = (t + d) | 0;
        c = (c + t) | 0;
        return (t >>> 0) / 4294967296;
    };
};

const makeRand = (str: string) => {
    const seed = xmur3(str);
    return sfc32(seed(), seed(), seed(), seed());
};

export const ScribbleNotebook: FC<ScribbleDefaultProps> = ({ id, innerRef, className, editor, children }) => {
    const { classes, cx } = useStyles();

    const rand = makeRand(id);
    const rotate = rand() * 2 - 1;
    const holeTopY = 15 + rand() * 12 - 6;
    const holeMiddleY = holeTopY + 35 + rand() * 12 - 6;
    const holeBottomY = holeMiddleY + 35 + rand() * 12 - 6;
    return (
        <Card
            ref={innerRef}
            className={cx(className, classes.ScribbleNotebook)}
            style={editor ? undefined : { transform: `rotate(${rotate}deg)` }}
        >
            {editor}
            <Card.Body className={classes.paper}>
                <div className={classes.lines}>
                    <div className={classes.text}>{children}</div>
                </div>
                <div className={classes.holes} style={{ transform: `translateY(${holeTopY}px)` }}></div>
                <div className={classes.holes} style={{ transform: `translateY(${holeMiddleY}px)` }}></div>
                <div className={classes.holes} style={{ transform: `translateY(${holeBottomY}px)` }}></div>
            </Card.Body>
        </Card>
    );
};

const useStyles = makeStyles()(() => ({
    ScribbleNotebook: {
        position: "relative",
    },
    paper: {
        position: "relative",
        background: "#fafad2",
        overflow: "hidden",

        "&:before": {
            content: "''",
            position: "absolute",
            left: "45px",
            height: "100%",
            width: "2px",
            background: "rgba(255, 0, 0, .4)",
        },
    },
    lines: {
        marginLeft: 40,
        backgroundImage: "repeating-linear-gradient(transparent 0px, transparent 24px, #4682b4 25px)",
    },
    text: {
        lineHeight: "25px",
    },
    holes: {
        position: "absolute",
        left: 10,
        top: 0,
        height: 20,
        width: 20,
        background: "#eeeeee",
        borderRadius: "50%",
        boxShadow: "inset 0px 0px 2px 0px #888",
    },
}));
