import { ChangeEventHandler, FC, useRef, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { makeStyles } from "./styles";
import { Scribble } from "./store";

interface ScribbleEditorProps {
    scribble: Scribble;
    onSave: (value: string) => void;
    onCancel: () => void;
}

export const ScribbleEditor: FC<ScribbleEditorProps> = ({ scribble, onSave, onCancel }) => {
    const [textareaText, setTextareaText] = useState("");
    const ref = useRef<HTMLTextAreaElement | null>(null);
    const { classes, cx } = useStyles();

    useEffect(() => {
        setTextareaText(scribble.text);
        ref.current?.focus();
    }, [scribble]);

    const onTextareaChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        setTextareaText(event.target.value);
    };

    return (
        <>
            <Card className={cx(classes.ScribbleEditor, "shadow")}>
                <Card.Body className={classes.body}>
                    <textarea
                        ref={ref}
                        className={classes.textArea}
                        value={textareaText}
                        onChange={onTextareaChange}
                        spellCheck="false"
                    />
                    <div className={classes.buttons}>
                        <Button onClick={() => onSave(textareaText)}>Save</Button>
                        <Button onClick={onCancel}>Cancel</Button>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

const useStyles = makeStyles()((theme, _params, classes) => ({
    ScribbleEditor: {
        flex: 1,
    },
    body: {
        display: "flex",
        flexDirection: "column",
    },
    textArea: {
        textAlign: "right",
        flex: 1,
        border: 0,
        outline: 0,
        padding: "3px 0 0 0",
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: theme.space,
        gap: theme.space,
    },
}));
