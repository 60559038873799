import { FC, useState } from "react";
import Container from "react-bootstrap/Container";

import { Poem } from "./store";
import { orderPoemItems } from "./util";
import { Cell } from "./Cell";
import { makeStyles } from "./styles";

interface FavoritePoemProps {
    poem: Poem;
    selectPoem: (poem: Poem) => void;
    selected: boolean;
    side: "left" | "right";
}

const FavoritePoem: FC<FavoritePoemProps> = ({ poem, side }) => {
    /*
    const { setPoem } = useStore();
    const [order, setOrder] = useState(poem.order);

    const swap1 = () => {
        const newOrder = order ^ 1;
        setPoem({ ...poem, order: newOrder });
        setOrder(newOrder);
    };
    const swap2 = () => {
        const newOrder = order ^ 2;
        setPoem({ ...poem, order: newOrder });
        setOrder(newOrder);
    };
    */

    const [item1, item2, item3, item4] = orderPoemItems(poem);

    const forRight = side === "right";

    const makeCell = (columnIndex: number) => (
        <Cell
            columnIndex={columnIndex + (forRight ? 3 : 0)}
            rowIndex={0}
            style={{}}
            data={{
                searchResult: [[forRight ? [item2, item1, item4, item3] : [item1, item2, item3, item4], 0, 0]],
                setQueryInput: () => {},
            }}
        />
    );

    return (
        <div className="d-flex flex-wrap justify-content-end">
            {makeCell(0)}
            {","}
            {makeCell(1)}
        </div>
    );
};

interface FavoritePoemsProps {
    poems: Poem[];
    side: "left" | "right";
}

export const FavoritePoems: FC<FavoritePoemsProps> = ({ poems, side }) => {
    const [poemHash, setPoemHash] = useState(0);

    const { classes } = useStyles();

    const selectPoem = (poem: Poem) => {
        setPoemHash(poem.hash);
    };

    const renderedPoems = poems.map((poem) => (
        <FavoritePoem
            key={poem.hash}
            side={side}
            poem={poem}
            selectPoem={selectPoem}
            selected={poemHash === poem.hash}
        />
    ));

    return (
        <Container className={classes.profileContainer} fluid={true}>
            {renderedPoems}
        </Container>
    );
};

const useStyles = makeStyles()(() => ({
    profileContainer: {},
}));
