import { createMakeStyles } from "tss-react";

// @see https://github.com/garronej/tss-react

function useTheme() {
    return {
        primaryColor: "#32CD32",
        space: 8,
    };
}

export const { makeStyles } = createMakeStyles({ useTheme });

export const important = <T extends unknown>(value: T): T => (value + " !important") as any;
