import React, { CSSProperties, FC } from "react";
import { Dice1Fill, Dice2Fill, Dice3Fill, Dice4Fill, Dice5Fill, Dice6Fill } from "react-bootstrap-icons";

// FIXME: Dice erzeugt Redraw der App
export const DiceIcon: FC<{ rotation: number }> = ({ rotation }) => {
    const dice = Math.floor(Math.random() * 6);
    const style = (index: number): CSSProperties => {
        if (rotation === 0) {
            if (index === 0) return {};
        } else if (dice === index) {
            let y = (rotation + 4) % 16;
            if (y >= 8) y = 16 - y;
            return { transform: `rotate(${rotation * 27}deg) translateY(${y - 4}px)` };
        }
        return { display: "none" };
    };
    return (
        <>
            <Dice1Fill style={style(4)} />
            <Dice2Fill style={style(5)} />
            <Dice3Fill style={style(0)} />
            <Dice4Fill style={style(1)} />
            <Dice5Fill style={style(2)} />
            <Dice6Fill style={style(3)} />
        </>
    );
};
