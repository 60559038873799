import { useCallback } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { randomBytes } from "crypto";

interface StorageReturnValue {
    clear: () => void;
    getValue: (key: string) => string | undefined;
    removeValue: (key: string) => void;
    setValue: (key: string, value: string) => void;
}

const Storage = (): StorageReturnValue => {
    const storage = window.localStorage;

    const isLocalStorageSupported = (() => {
        try {
            const key = "$$$";
            storage.setItem(key, key);
            storage.removeItem(key);
            return true;
        } catch (e) {
            return false;
        }
    })();

    if (isLocalStorageSupported) {
        return {
            clear() {
                storage.clear();
            },
            getValue(key: string) {
                return storage.getItem(key) ?? undefined;
            },
            removeValue(key: string) {
                storage.removeItem(key);
            },
            setValue(key: string, value: string) {
                storage.setItem(key, value);
            },
        };
    }

    const memStorage = new Map<string, string>();
    return {
        clear() {
            memStorage.clear();
        },
        getValue(key: string) {
            return memStorage.get(key);
        },
        removeValue(key: string) {
            memStorage.delete(key);
        },
        setValue(key: string, value: string) {
            memStorage.set(key, value);
        },
    };
};

const calcHash = (str: string) => Array.from(str).reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0);

export type Items = [item0: string, item1: string, item2: string, item3: string];

export const makeHashFromItems = (items: Items) => calcHash(`${items[0]} ${items[1]} ${items[2]} ${items[3]}`);

export const makePoem = (items: Items, order = 0) => {
    const hash = makeHashFromItems(items);

    return {
        hash,
        items,
        order,
        toJSON() {
            return { items, order: this.order };
        },
    };
};

export type Poem = ReturnType<typeof makePoem>;

export const makeScribble = (text = "") => {
    const id = randomBytes(32).toString("hex");
    return {
        id,
        text,
    };
};

export type Scribble = ReturnType<typeof makeScribble>;

const poemFromJson = (json: any): Poem => {
    const poem = makePoem(json.items);
    poem.order = json.order ?? 0;
    return poem;
};

const storage = Storage();

const setObject = (key: string, value: any): any => {
    storage.setValue(key, JSON.stringify(value));
};

const getObject = (key: string): any => {
    try {
        return JSON.parse(storage.getValue(key) ?? "");
    } catch (_) {
        return undefined;
    }
};

const poemsJson = (getObject("poems") ?? []) as any[];
const scribblesJson = (getObject("scribbles") ?? []) as Scribble[];

const initialDbState: { poems: Poem[]; scribbles: Scribble[] } = {
    poems: poemsJson.map((json) => poemFromJson(json)),
    scribbles: scribblesJson,
};

const { useGlobalState: useDbState } = createGlobalState(initialDbState);

export const useDbStore = () => {
    const [poems, setPoems_] = useDbState("poems");

    const setPoems = useCallback(
        (poems: Poem[]) => {
            setObject(
                "poems",
                poems.map((poem) => poem.toJSON())
            );
            setPoems_(poems);
        },
        [setPoems_]
    );

    const addPoem = useCallback(
        (poem: Poem) => {
            if (!poems.find((poem_) => poem_.hash === poem.hash)) {
                setPoems([poem, ...poems]);
            }
        },
        [poems, setPoems]
    );

    const removePoem = useCallback(
        (poem: Poem) => {
            const index = poems.findIndex((poem_) => poem_.hash === poem.hash, undefined);
            if (index >= 0) {
                poems.splice(index, 1);
                setPoems([...poems]);
            }
        },
        [poems, setPoems]
    );

    const setPoem = useCallback(
        (poem: Poem) => {
            const index = poems.findIndex((poem_) => poem_.hash === poem.hash, undefined);
            if (index >= 0) {
                poems[index] = poem;
                setPoems([...poems]);
            } else {
                addPoem(poem);
            }
        },
        [poems, setPoems, addPoem]
    );

    const getPoemByItems = useCallback(
        (items: Items): Poem | undefined => {
            const hash = makeHashFromItems(items);
            return poems.find((poem_) => poem_.hash === hash);
        },
        [poems]
    );

    const [scribbles, setScribbles_] = useDbState("scribbles");

    const setScribbles = useCallback(
        (scribbles: Scribble[]) => {
            setObject("scribbles", scribbles);
            setScribbles_(scribbles);
        },
        [setScribbles_]
    );

    const addScribble = useCallback(
        (scribble: Scribble) => {
            if (!scribbles.find((scribble_) => scribble_.id === scribble.id)) {
                setScribbles([scribble, ...scribbles]);
            }
        },
        [scribbles, setScribbles]
    );

    const removeScribble = useCallback(
        (scribble: Scribble) => {
            const index = scribbles.findIndex((scribble_) => scribble_.id === scribble.id, undefined);
            if (index >= 0) {
                scribbles.splice(index, 1);
                setScribbles([...scribbles]);
            }
        },
        [scribbles, setScribbles]
    );

    const updateScribble = useCallback(
        (scribble: Scribble) => {
            const index = scribbles.findIndex((scribble_) => scribble_.id === scribble.id, undefined);
            if (index >= 0) {
                scribbles[index] = scribble;
                setScribbles([...scribbles]);
            } else {
                addScribble(scribble);
            }
        },
        [scribbles, setScribbles, addScribble]
    );

    const getScribbles = useCallback(
        ({ search }: { search?: string }) => {
            if (!search) return scribbles;

            const parts = search?.split(/\s+/).filter((part) => part.length) ?? [];

            return scribbles
                .map((scribble, index): [Scribble, number] | undefined => {
                    // TODO: Add ranking
                    const score = parts.filter((part) => scribble.text.includes(part)).length;
                    if (score) return [scribble, index + score * -10000];
                    return undefined;
                })
                .filter((item) => item !== undefined)
                .sort((a, b) => b![1] - a![1])
                .map((item) => item![0]);
        },
        [scribbles]
    );

    return {
        poems,
        addPoem,
        removePoem,
        setPoem,
        getPoemByItems,
        addScribble,
        removeScribble,
        updateScribble,
        getScribbles,
    };
};

export interface OverCell {
    rowIndex: number;
    columnIndex: number;
}

export interface EditScribble {
    scribble: Scribble;
    isNew: boolean;
}

const initialAppState: { queryInput: string; overCell: OverCell | undefined; editScribble: EditScribble | undefined } =
    {
        queryInput: "",
        overCell: undefined,
        editScribble: undefined,
    };

const { useGlobalState: useAppState } = createGlobalState(initialAppState);

export const useAppStore = () => {
    const [queryInput, setQueryInput] = useAppState("queryInput");
    const [overCell, setOverCell] = useAppState("overCell");
    const [editScribble, setEditScribble] = useAppState("editScribble");

    return {
        queryInput,
        setQueryInput,
        overCell,
        setOverCell,
        editScribble,
        setEditScribble,
    };
};

export const useStore = () => {
    const dbStore = useDbStore();
    const appStore = useAppStore();

    return {
        ...dbStore,
        ...appStore,
    };
};
