import React, { FC } from "react";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";

import { CorpusInfo } from "./schuettel";

// FIXME: Nicht aus App importieren
import { LANG } from "./App";
import { makeStyles } from "./styles";

interface CorpusSelectProps {
    corpusInfos: CorpusInfo[];
    corpusId: string;
    setCorpusId: (corpusId: string) => void;
}
export const CorpusSelect: FC<CorpusSelectProps> = ({ corpusInfos, corpusId, setCorpusId }) => {
    const { classes } = useStyles();

    const corpusPropI18n = (corpusInfo: CorpusInfo, prop: "title" | "source") =>
        corpusInfo[`${prop}_${LANG}`] || corpusInfo[prop];

    return (
        <ListGroup>
            {corpusInfos.map((corpusInfo) => (
                <ListGroup.Item
                    key={corpusInfo.corpusId}
                    className={classes.corpusItem}
                    active={corpusInfo.corpusId === corpusId}
                    onClick={() => setCorpusId(corpusInfo.corpusId)}
                >
                    <div>
                        <div className={classes.fwBold}>{corpusPropI18n(corpusInfo, "title")}</div>
                        {corpusPropI18n(corpusInfo, "source")}
                    </div>
                    <Badge bg="info">{corpusInfo.lang}</Badge>{" "}
                    <Badge bg="info">{corpusInfo.class3wordsCount} words</Badge>{" "}
                    <Badge bg="info">{corpusInfo.class2pairsCount} pairs</Badge>
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
};

const useStyles = makeStyles()(() => ({
    corpusItem: {
        cursor: "pointer",
    },
    fwBold: {
        // FIXME: bolder?
    },
}));
