import React from "react";

import fetchProgress from "fetch-progress";
import { createGlobalState } from "react-hooks-global-state";

/*
Ideen:

Problem "einfa"
- Im Corpus "- " entfernen, ausser:
--- es folgt "und", "oder" (Arbeiter- und Bauernstaat)
--- es geht ein "und" voran (Autoren und -autorinnen)

Problem
- "ch" hat oft ein fehlerhaftes Leerzeichen davor

*/

const MAX_QUERY_VARIANTS = 10;

// > > > > > > From Mkdata

type IpaInfo = [word: string, score: number, wordIndex: number];
type WordInfo = [word: string, docFreq: number, start: string, end: string];

let wordInfos: Array<WordInfo> = [];
let wordToIndex = new Map<string, number>();
let ipaLookup = new Map<string, IpaInfo>();
let endStarts = new Map<string, Set<string>>();
let startEnds = new Map<string, Set<string>>();
let class2pairsLookup = new Map<string, Set<string>>();
let compoundLookup = new Set<string>();

const setupClass3 = function* (lines: string[]) {
    let lastTime = Date.now();

    wordInfos.length = 0;
    wordToIndex.clear();
    ipaLookup.clear();
    endStarts.clear();
    startEnds.clear();

    for (const line of lines) {
        const time = Date.now();
        if (time - lastTime > 300) {
            lastTime = time;
            // For App:
            yield;
        }

        const parts = line.split(" ");
        if (parts.length !== 4) {
            throw new Error("Line in class3wordsFile needs 4 parts: " + line);
        }
        const [word, docFreqStr, start, end] = parts;
        wordToIndex.set(word, wordInfos.length);

        const ipa = start + "ˈ" + end;
        if (!ipaLookup.has(ipa) || ipaLookup.get(ipa)![1] < +docFreqStr) {
            ipaLookup.set(start + "ˈ" + end, [word, +docFreqStr, wordInfos.length]);
        }
        if (endStarts.has(end)) {
            endStarts.get(end)!.add(start);
        } else {
            endStarts.set(end, new Set([start]));
        }
        if (startEnds.has(start)) {
            startEnds.get(start)!.add(end);
        } else {
            startEnds.set(start, new Set([end]));
        }

        wordInfos.push([word, +docFreqStr, start, end]);
    }
};

const setupClass2pairs = function* (lines: string[]) {
    class2pairsLookup.clear();

    for (const line of lines) {
        const parts = line.split(" ");
        if (parts.length < 2) {
            throw new Error("Line in class2pairsFile needs min 2 parts: " + line);
        }
        const word1Index = +parts.shift()!;
        const [, , start1, end1] = wordInfos[word1Index];
        const ipa1 = start1 + "ˈ" + end1;
        if (!class2pairsLookup.has(ipa1)) {
            class2pairsLookup.set(ipa1, new Set());
        }
        const pairIpas = class2pairsLookup.get(ipa1)!;
        let word2Index = 0;
        parts.forEach((word2IndexDiffStr) => {
            word2Index += +word2IndexDiffStr;
            const [, , start2, end2] = wordInfos[word2Index];
            const ipa2 = start2 + "ˈ" + end2;
            pairIpas.add(ipa2);
        });
    }

    // For App:
    yield;
};

const setupCompounds = function (lines: string[]) {
    compoundLookup = new Set();
    if (!wordInfos.length) return;

    for (const line of lines) {
        const parts = line.split(" ");
        if (parts.length !== 2) {
            throw new Error("Line in compoundsFile needs 2 parts: " + line);
        }
        const [word1IndexStr, word2IndexStr] = parts;
        compoundLookup.add(wordInfos[+word1IndexStr][0] + wordInfos[+word2IndexStr][0]);
    }
};

const wordsToEndStarts = (wordInfos: WordInfo[]) => {
    const endStarts = new Map<string, Set<string>>();
    wordInfos.forEach(([, , start, end]) => {
        if (endStarts.has(end)) {
            endStarts.get(end)!.add(start);
        } else {
            endStarts.set(end, new Set([start]));
        }
    });
    return endStarts;
};

const wordsToStartEnds = (wordInfos: WordInfo[]) => {
    const startEnds = new Map<string, Set<string>>();
    wordInfos.forEach(([, , start, end]) => {
        if (startEnds.has(start)) {
            startEnds.get(start)!.add(end);
        } else {
            startEnds.set(start, new Set([end]));
        }
    });
    return startEnds;
};

const PI_2 = Math.PI / 2;
const normalizeScore = (n: number) => Math.atan(n / 500) / PI_2;
const buildScore = (words: IpaInfo[], compound1Freq: number, compound2Freq: number) =>
    (compound1Freq ? 2 * normalizeScore(compound1Freq) : normalizeScore(words[0][1]) + normalizeScore(words[1][1])) +
    (compound2Freq ? 2 * normalizeScore(compound2Freq) : normalizeScore(words[2][1]) + normalizeScore(words[3][1]));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _buildScore = (words: IpaInfo[], compound1Freq: number, compound2Freq: number) =>
    normalizeScore(words[0][1]) +
    normalizeScore(words[1][1]) +
    normalizeScore(words[2][1]) +
    normalizeScore(words[3][1]) +
    (compound1Freq ? 1 : 0) +
    (compound2Freq ? 1 : 0);

export type ResultRecord = [
    value: [word1: string, word2: string, word3: string, word4: string],
    class_: number,
    score: number
];

export const normalizeQuery = (query: string) => query.trim().toLowerCase(); // .normalize("NFKC");

const runSearch_ = function* (normalizedQuery: string) {
    let lastTime = Date.now();

    const queryWords = normalizedQuery.split(/\s+/).map(
        (query) =>
            wordInfos
                .filter((info) => info[0].endsWith(query))
                .sort((a, b) => a[0].length - b[0].length)
                .filter((info, _, arr) => info[0].length <= arr[0][0].length + 3)
                .slice(0, MAX_QUERY_VARIANTS) // FIXME: Notwendig?
    );

    // Damit console.log auch korrekt anzeigt, wenn queryWords geändert wurde
    // console.log("query1", [...queryWords]);

    const queryEndStarts = wordsToEndStarts(queryWords.shift()!);
    const queryRestStartEnds = queryWords.length ? wordsToStartEnds(queryWords.flat()) : undefined;

    // console.log("queryRestStartEnds", queryRestStartEnds);

    // const queryEndStarts = queryEndStarts1.shift()!;
    // const queryRestEndStarts = new Set(queryEndStarts1.flat());
    // const queryRestStarts: string[] = queryRestStartEnds ? Array.from(queryRestStartEnds.keys()) : undefined;
    // throw "abc";

    console.time("search");

    const queryEnds = Array.from(queryEndStarts.keys());
    const results: ResultRecord[] = [];

    // Used in App
    const makeReturnValue = () => results.sort((a, b) => a[1] - b[1] || b[2] - a[2]);

    const seenEnds = new Set<string>();
    for (const end of queryEnds) {
        // starts=[kl] end=apper
        seenEnds.add(end);
        const starts = queryEndStarts.get(end)!;

        const seenStarts = new Set<string>();
        for (const start of starts) {
            // start=kl end=apper
            seenStarts.add(start);

            const ends2 = startEnds.get(start)!;
            for (const end2 of ends2) {
                if (seenEnds.has(end2)) continue;

                const time = Date.now();
                if (time - lastTime > 200) {
                    lastTime = time;
                    // Used in App
                    yield makeReturnValue();
                }

                // start=kl end=apper end2=angen
                const starts2 = endStarts.get(end2)!;
                for (const start2 of starts2) {
                    if (seenStarts.has(start2)) continue;

                    const startEnds1 = queryRestStartEnds ?? startEnds;

                    // start=kl end=apper end2=angen start2=schl
                    if (startEnds1.get(start2)?.has(end)) {
                        let words = [
                            ipaLookup.get(start + "ˈ" + end)!,
                            ipaLookup.get(start2 + "ˈ" + end2)!,
                            ipaLookup.get(start2 + "ˈ" + end)!,
                            ipaLookup.get(start + "ˈ" + end2)!,
                        ];

                        let ipas = [start + "ˈ" + end, start2 + "ˈ" + end2, start2 + "ˈ" + end, start + "ˈ" + end2];

                        // klapper schlang
                        const word01IsClass2 = class2pairsLookup.get(ipas[0])?.has(ipas[1]);
                        // schlapper klang
                        const word23IsClass2 = class2pairsLookup.get(ipas[2])?.has(ipas[3]);
                        // schlang klapper
                        const word10IsClass2 = class2pairsLookup.get(ipas[1])?.has(ipas[0]);
                        // klang schlapper
                        const word32IsClass2 = class2pairsLookup.get(ipas[3])?.has(ipas[2]);

                        let class_ = 3;
                        let words1 = words;

                        if (word01IsClass2 || word10IsClass2 || word23IsClass2 || word32IsClass2) {
                            const checkClass = (
                                b0: boolean | undefined,
                                b1: boolean | undefined,
                                i00: number,
                                i01: number,
                                i10: number,
                                i11: number
                            ) => {
                                if (b0 || b1) {
                                    class_ = 2;
                                    words1 = [words[i00], words[i01], words[i10], words[i11]];
                                }
                                if (b0 && b1) {
                                    class_ = 1;
                                    return true;
                                }
                                return false;
                            };

                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            checkClass(word01IsClass2, word23IsClass2, 0, 1, 2, 3) ||
                                checkClass(word10IsClass2, word32IsClass2, 1, 0, 3, 2);
                        }

                        let [word1, word2, word3, word4] = [words1[0][0], words1[1][0], words1[2][0], words1[3][0]];

                        const compound1 = word1 + word2;
                        const compound2 = word3 + word4;

                        const compound1Freq = compoundLookup.has(compound1)
                            ? wordToIndex.has(compound1)
                                ? wordInfos[wordToIndex.get(compound1)!][1]
                                : 1000
                            : 0;
                        const compound2Freq = compoundLookup.has(compound2)
                            ? wordToIndex.has(compound2)
                                ? wordInfos[wordToIndex.get(compound2)!][1]
                                : 1000
                            : 0;

                        if (compoundLookup.has(word1 + word2)) {
                            word2 = "-" + word2;
                        }
                        if (compoundLookup.has(word3 + word4)) {
                            word4 = "-" + word4;
                        }

                        results.push([
                            [word1, word2, word3, word4],
                            class_,
                            buildScore(words1, compound1Freq, compound2Freq),
                        ]);
                    }
                }
            }
        }
    }

    // In App:
    // console.log("YIELD (last)", id, Date.now() - lastTime);
    yield makeReturnValue();

    console.timeEnd("search");

    // In Mkdata:
    // return makeReturnValue();
};

// < < < < < < From Mkdata

// FIXME: Warnung bei Space
// FIXME: Moeglicherweise Problem bei vertauschten Worten
export const makeRhymes = (normalizedQuery: string, results: ResultRecord[]) => {
    const queryWords = wordInfos.filter((info) => info[0].endsWith(normalizedQuery));
    // console.log(normalizedQuery);
    const queryEnds = new Set(wordsToEndStarts(queryWords).keys());
    let allRhymes = wordInfos.filter((info) => queryEnds.has(info[3]));
    if (results && results.length) {
        const allResultRhymes = new Set<string>(results.map((result) => result[0][2]));
        // console.log(allResultRhymes);
        allRhymes = allRhymes.filter((info) => allResultRhymes.has(info[0]));
    }

    return allRhymes
        .sort((a, b) => a[0].length - b[0].length || b[1] - a[1])
        .map((info) => info[0])
        .splice(0, 100);
};

const readTextFile = async (file: string, onProgress: (transferred: number) => void) => {
    const data = await fetch(file);
    const progress = fetchProgress({
        onProgress: (progress) => onProgress(progress.transferred),
        onError(err) {
            throw err;
        },
    });
    const data1 = await progress(data);
    const text = await data1.text();
    if (text.startsWith("<")) {
        throw new Error("file " + file + " not found");
    }
    return text.split("\n");
};

// done: running=false, done=true, aborted=undefined
type RunTaskCallback<T> = (done: boolean | undefined, value?: T) => void;

const runTask = <T>(gen: Generator<T>, onError: (err: unknown) => void, setState: RunTaskCallback<T>) => {
    let t = 0;
    const next = () => {
        t = window.setTimeout(() => {
            try {
                const result = gen.next();
                if (result.done) {
                    setState(true);
                    t = 0;
                    return;
                }
                setState(false, result.value);
            } catch (err) {
                onError(err);
            }
            next();
        }, 1);
    };
    next();
    return {
        abort() {
            console.log("ABORTED");
            window.clearTimeout(t);
            t = 0;
            setState(undefined);
        },
    };
};

type RunTask = ReturnType<typeof runTask>;

interface LoadState {
    config: "initial" | "loading" | "ready" | "error";
    class3words: "initial" | "loading" | "setup" | "ready" | "error";
    class3wordsTransferred: number;
    class3wordsLines: string[];
    class2pairs: "initial" | "loading" | "loading-done" | "setup" | "ready" | "error";
    class2pairsTransferred: number;
    class2pairsLines: string[];
    compounds: "initial" | "loading" | "loading-done" | "setup" | "ready" | "error";
    compoundsTransferred: number;
    compoundsLines: string[];
    error: string;
}

const initialLoadState: LoadState = {
    config: "initial",
    class3words: "initial",
    class3wordsTransferred: 0,
    class3wordsLines: [],
    class2pairs: "initial",
    class2pairsTransferred: 0,
    class2pairsLines: [],
    compounds: "initial",
    compoundsTransferred: 0,
    compoundsLines: [],
    error: "",
};

export interface CorpusInfo {
    corpusId: string;
    title: string;
    source: string;
    title_de: string;
    source_de: string;
    lang: string;
    class3wordsFile: string;
    class3wordsFileSize: number;
    class3wordsCount: number;
    class2pairsFile: string;
    class2pairsFileSize: number;
    class2pairsCount: number;
    compoundsFile: string;
    compoundsFileSize: number;
}

const makeConfig = (corpusId: string, json: any) => {
    let corpusInfos: CorpusInfo[] = [];

    const error = (error: string): never => {
        throw new Error("Config JSON: " + error);
    };

    const needString = (json: any, prop: string) => {
        if (!(typeof json[prop] === "string")) error(`Prop "${prop}" is a String`);
        return json[prop];
    };

    const needNumber = (json: any, prop: string) => {
        if (!(typeof json[prop] === "number")) error(`Prop "${prop}" is a String`);
        return json[prop];
    };

    if (!Array.isArray(json)) error("Must be an array");
    json.forEach((corpusJson: any) => {
        corpusInfos.push({
            corpusId: needString(corpusJson, "corpusId"),
            title: needString(corpusJson, "title"),
            source: needString(corpusJson, "source"),
            title_de: needString(corpusJson, "title_de"),
            source_de: needString(corpusJson, "source_de"),
            lang: needString(corpusJson, "lang"),
            class3wordsFile: needString(corpusJson, "class3wordsFile"),
            class3wordsFileSize: needNumber(corpusJson, "class3wordsFileSize"),
            class3wordsCount: needNumber(corpusJson, "class3wordsCount"),
            class2pairsFile: needString(corpusJson, "class2pairsFile"),
            class2pairsFileSize: needNumber(corpusJson, "class2pairsFileSize"),
            class2pairsCount: needNumber(corpusJson, "class2pairsCount"),
            compoundsFile: needString(corpusJson, "compoundsFile"),
            compoundsFileSize: needNumber(corpusJson, "compoundsFileSize"),
        });
    });

    const progress = (
        corpusInfo: CorpusInfo,
        loadState: LoadState,
        prop: "class3words" | "class2pairs" | "compounds"
    ) => {
        if (loadState[`${prop}Transferred`] > corpusInfo![`${prop}FileSize`]) return 1;

        return loadState[`${prop}Transferred`] / corpusInfo![`${prop}FileSize`];
    };

    return {
        progress,
        corpusInfos,
    };
};

type Config = ReturnType<typeof makeConfig>;

let config: Config = {
    progress: () => 0,
    corpusInfos: [],
};

const initialSchuettelState: {
    loadState: LoadState;
    queryTask: RunTask | undefined;
    lastQuery: string;
    heartbeat: number;
    lastCorpusId: string;
} = {
    loadState: { ...initialLoadState },
    queryTask: undefined,
    lastQuery: "",
    heartbeat: 0,
    lastCorpusId: "",
};

const { useGlobalState: useSchuettelState } = createGlobalState(initialSchuettelState);

type LoadProgress = [class3words: number, class2pairs: number, compounds: number];

const ZERO_PROGRESS: LoadProgress = [0, 0, 0];

export const useSchuettel = (corpusId: string) => {
    const [loadState, setLoadState] = useSchuettelState("loadState");
    const [queryTask, setQueryTask] = useSchuettelState("queryTask");
    const [lastQuery, setLastQuery] = useSchuettelState("lastQuery");
    const [heartbeat, setHeartbeat] = useSchuettelState("heartbeat");
    const [lastCorpusId, setLastCorpusId] = useSchuettelState("lastCorpusId");

    const corpusInfo = React.useMemo(() => {
        return config.corpusInfos.find((corpusInfo) => corpusInfo.corpusId === corpusId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [corpusId, loadState.config]);

    // FIXME: Remove, unused
    const addHeartbeat = React.useCallback(() => {
        setHeartbeat((heartbeat) => {
            console.log("heartbeat");
            return heartbeat + 1 + Math.random();
        });
    }, [setHeartbeat]);

    const corpusReady =
        loadState.class3words === "ready" && loadState.class2pairs === "ready" && loadState.compounds === "ready";

    const pending =
        loadState.class3words === "loading" ||
        loadState.class3words === "setup" ||
        loadState.class2pairs === "loading" ||
        loadState.class2pairs === "loading-done" ||
        loadState.class2pairs === "setup" ||
        loadState.compounds === "loading" ||
        loadState.compounds === "loading-done" ||
        loadState.compounds === "setup";

    React.useEffect(() => {
        if (corpusId !== lastCorpusId && !pending) {
            // FIXME: Kann ausgeführt werden während Daten gelesen werden, das führt zum Chaos
            setLastCorpusId(corpusId);
            setLoadState((state) => ({
                ...state,
                class3wordsTransferred: 0,
                class2pairsTransferred: 0,
                compoundsTransferred: 0,
                class3words: "initial",
                class2pairs: "initial",
                compounds: "initial",
                error: "",
            }));
            return;
        }

        if (loadState.error) {
            return;
        }

        const class3wordsFile = "data/" + corpusId + ".s5.class3.txt";
        const class2pairsFile = "data/" + corpusId + ".s8.class2_pairs.txt";
        const compoundsFile = "data/" + corpusId + ".s6.compounds.txt";

        const setError = (error: unknown, loadState2: Partial<LoadState>) => {
            console.log("ERROR", error);
            setLoadState((state) => ({
                ...state,
                ...loadState2,
                error: typeof error === "object" && error !== null ? error.toString() : "Unknown error",
            }));
        };

        const makeSetError = (loadState2: Partial<LoadState>) => (error: unknown) => setError(error, loadState2);

        // console.log("loadState", loadState.config, loadState.class3words, loadState.class2pairs, loadState.compounds);

        if (loadState.config === "initial") {
            setLoadState((state) => ({ ...state, config: "loading" }));
            fetch("config.json")
                .then((data) => data.json())
                .then((json) => {
                    config = makeConfig(corpusId, json);
                    setLoadState((state) => ({ ...state, config: "ready" }));
                })
                .catch(makeSetError({ config: "error" }));
        }

        if (corpusId === "") return;

        if (loadState.config === "ready" && loadState.class3words === "initial") {
            const setError2 = makeSetError({ class3words: "error" });
            setLoadState((state) => ({ ...state, class3words: "loading" }));
            readTextFile(class3wordsFile, (transferred) => {
                setLoadState((state) => ({ ...state, class3wordsTransferred: transferred }));
            })
                .then((class3wordsLines) => {
                    setLoadState((state) => ({
                        ...state,
                        class3words: "setup",
                        class3wordsLines,
                    }));
                    runTask(setupClass3(class3wordsLines), setError2, (done) => {
                        if (done) {
                            setLoadState((state) => ({ ...state, class3words: "ready", class3wordsLines: [] }));
                        }
                    });
                })
                .catch(setError2);
        }
        if (loadState.config === "ready" && loadState.class2pairs === "initial") {
            const setError2 = makeSetError({ class2pairs: "error" });
            setLoadState((state) => ({ ...state, class2pairs: "loading" }));
            readTextFile(class2pairsFile, (transferred) => {
                setLoadState((state) => ({ ...state, class2pairsTransferred: transferred }));
            })
                .then((class2pairsLines) =>
                    setLoadState((state) => ({ ...state, class2pairs: "loading-done", class2pairsLines }))
                )
                .catch(setError2);
        }
        if (loadState.config === "ready" && loadState.compounds === "initial") {
            const setError2 = makeSetError({ compounds: "error" });
            setLoadState((state) => ({ ...state, compounds: "loading" }));
            readTextFile(compoundsFile, (transferred) => {
                setLoadState((state) => ({ ...state, compoundsTransferred: transferred }));
            })
                .then((compoundsLines) =>
                    setLoadState((state) => ({ ...state, compounds: "loading-done", compoundsLines }))
                )
                .catch(setError2);
        }
        if (loadState.class3words === "ready" && loadState.class2pairs === "loading-done") {
            const setError2 = makeSetError({ class2pairs: "error" });
            try {
                setLoadState((state) => ({ ...state, class2pairs: "setup" }));
                runTask(setupClass2pairs(loadState.class2pairsLines), setError2, (done) => {
                    if (done) {
                        setLoadState((state) => ({ ...state, class2pairs: "ready", class2pairsLines: [] }));
                    }
                });
            } catch (error) {
                setError2(error);
            }
        }
        if (loadState.class3words === "ready" && loadState.compounds === "loading-done") {
            const setError2 = makeSetError({ compounds: "error" });
            try {
                setLoadState((state) => ({ ...state, compounds: "setup" }));
                setupCompounds(loadState.compoundsLines);
                setLoadState((state) => ({ ...state, compounds: "ready", compoundsLines: [] }));
            } catch (error) {
                setError2(error);
            }
        }
    }, [loadState, addHeartbeat, corpusId, lastCorpusId, setLastCorpusId, setLoadState, corpusReady, pending]);

    React.useEffect(() => {
        setLastQuery("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [corpusReady]);

    const runSearch = React.useCallback(
        (query: string, cb: RunTaskCallback<ResultRecord[]>) => {
            // console.log("runSearch Inside", lastQuery, query);
            if (lastQuery !== query) {
                if (queryTask) {
                    queryTask.abort();
                }
                if (query) {
                    // FIXME: Error handler
                    setQueryTask(() => runTask(runSearch_(query), () => {}, cb));
                } else {
                    // Ergebnisliste zurücksetzen
                    cb(undefined, []);
                }
                setLastQuery(query);
            }
        },
        [lastQuery, queryTask, setLastQuery, setQueryTask]
    );

    const randomWord = React.useCallback((): string => {
        if (class2pairsLookup.size === 0) return "";

        const ipas = Array.from(class2pairsLookup.keys());
        const ipa = ipas[Math.floor(Math.random() * class2pairsLookup.size)];
        return ipaLookup.get(ipa)?.[0] ?? "";
    }, []);

    const loadProgress: LoadProgress =
        !corpusReady && corpusInfo
            ? [
                  config.progress(corpusInfo, loadState, "class3words"),
                  config.progress(corpusInfo, loadState, "class2pairs"),
                  config.progress(corpusInfo, loadState, "compounds"),
              ]
            : ZERO_PROGRESS;

    return {
        heartbeat,
        corpusReady,
        loadProgress,
        loadError: loadState.error,
        runSearch,
        randomWord,
        corpusInfos: config.corpusInfos,
        corpusInfo,
    };
};

export type RunSearch = ReturnType<typeof useSchuettel>["runSearch"];
export type RandomWord = ReturnType<typeof useSchuettel>["randomWord"];
