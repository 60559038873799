// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getFirestore, collection, getDocs, Firestore } from "firebase/firestore/lite";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCVQ9iHgOkdLx_rIEnQNZwkd3y6IyBXyh0",
    authDomain: "schuettelreim-54a4f.firebaseapp.com",
    projectId: "schuettelreim-54a4f",
    storageBucket: "schuettelreim-54a4f.appspot.com",
    messagingSenderId: "606894516190",
    appId: "1:606894516190:web:e6ff7536430398ac711f4f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

signInAnonymously(auth).then((a: any) => {
    console.log("ANON", a);
    getCities(db);
});

onAuthStateChanged(auth, (user) => {
    console.log("AUTH", user);
    // Check for user status
});

const db = getFirestore(app);

// Get a list of cities from your database
async function getCities(db: Firestore) {
    try {
        const citiesCol = collection(db, "restaurants");
        const citySnapshot = await getDocs(citiesCol);
        const cityList = citySnapshot.docs.map((doc) => doc.data());
        console.log(cityList);
    } catch (e) {
        console.log(e);
    }
}
