import { Poem } from "./store";

export const itemParts = (item1: string, item2: string) => {
    return {
        item1: item1.replace(/^-/, ""),
        item2: item2.replace(/^-/, ""),
        isCompound: item2.startsWith("-"),
    };
};

export const toLine = (_item1: string, _item2: string) => {
    const { item1, item2, isCompound } = itemParts(_item1, _item2);
    return isCompound ? item1 + item2 : item1 + " " + item2;
};

export const orderPoemItems = (poem: Poem) => {
    let items = poem.items;
    if (poem.order & 1) {
        items = [items[1], items[0], items[3], items[2]];
    }
    if (poem.order & 2) {
        items = [items[2], items[3], items[0], items[1]];
    }
    return items;
};
