import React, { FC } from "react";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Container from "react-bootstrap/Container";

import { CorpusInfo } from "./schuettel";
import { CorpusSelect } from "./CorpusSelect";
import { makeStyles, important } from "./styles";

interface HeaderProps {
    corpusInfos: CorpusInfo[];
    corpusId: string;
    setCorpusId: (corpusId: string) => void;
}

export const Header: FC<HeaderProps> = ({ corpusInfos, corpusId, setCorpusId }) => {
    const { classes } = useStyles();
    return (
        <Container className={classes.Header} fluid>
            <Navbar className={classes.navbar} expand={false}>
                <Container fluid className={classes.inner}>
                    <Navbar.Text></Navbar.Text>
                    <Navbar.Text className={classes.logoAndTitle}>
                        <img className={classes.logo} alt="" src="logo6-omg.svg" />
                        <img className={classes.title} alt="" src="title2-omg.svg" />
                    </Navbar.Text>
                    <Navbar.Toggle aria-controls="offcanvasNavbar" />
                    <Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" placement="end">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <h6>Select Corpus</h6>
                            <CorpusSelect corpusInfos={corpusInfos} corpusId={corpusId} setCorpusId={setCorpusId} />
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </Container>
    );
};

const useStyles = makeStyles()((theme) => ({
    Header: {
        backgroundColor: "#fff",
    },
    navbar: {
        position: "relative",
        padding: 0,
        flexWrap: "nowrap",

        "& .navbar-toggler": {
            marginRight: important(0),
        },
    },
    inner: {
        padding: 0,
    },
    logoAndTitle: {
        width: "100%",
        maxWidth: 300,
        marginRight: theme.space,
    },
    logo: {
        width: "30%",
    },
    title: {
        width: "calc(70% - 15px)",
        marginLeft: 15,
    },
}));
