import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeGrid as Grid } from "react-window";

import { RandomWord, RunSearch } from "./schuettel";
import { RhymeSelect } from "./RhymeSelect";
import { DiceIcon } from "./DiceIcon";
import { makeRhymes, normalizeQuery, ResultRecord } from "./schuettel";
import { Poem, useStore } from "./store";
import { CellData } from "./Cell";
import { ResultGrid } from "./ResultGrid";
import { makeStyles } from "./styles";
import { useUpdateEffect } from "./hooks";

const MIN_CHARS = 4;

interface ExplorerProps {
    renderCorpusLoader: (() => ReactElement) | undefined;
    corpusId: string;
    poems: Poem[];
    runSearch: RunSearch;
    randomWord: RandomWord;
}

export const Explorer: FC<ExplorerProps> = ({ renderCorpusLoader, corpusId, poems, runSearch, randomWord }) => {
    const [searchResult, setSearchResult] = useState<ResultRecord[] | undefined>(undefined);
    const [query, setQuery] = useState("");
    const [selectedRhyme, setSelectedRhyme] = useState("");
    const [grid, setGrid] = useState<Grid | null>(null);
    const [diceRotation, setDiceRotation] = useState(0);

    const { queryInput, setQueryInput } = useStore();

    const { classes } = useStyles();
    const queryRhymes = useMemo(() => makeRhymes(query, searchResult || []), [query, searchResult]);

    useUpdateEffect(() => {
        setQueryInput("");
    }, [corpusId]);

    useEffect(() => {
        const normalizedQuery = normalizeQuery(queryInput);
        if (normalizedQuery !== query) {
            setQuery(normalizedQuery);
            setSelectedRhyme("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryInput]);

    useEffect(() => {
        grid?.forceUpdate();
    }, [grid, poems]);

    useEffect(() => {
        if (query.length >= MIN_CHARS) {
            const query1 = selectedRhyme ? query + " " + selectedRhyme : query;
            runSearch(query1, (done, value) => {
                if (done === false) {
                    setSearchResult(value);
                    grid?.forceUpdate();
                }
            });
        } else {
            runSearch("", () => {});
            setSearchResult(undefined);
            grid?.forceUpdate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, runSearch, selectedRhyme, grid, renderCorpusLoader !== undefined]);

    useEffect(() => {
        const h = window.setTimeout(() => {
            if (diceRotation) {
                setDiceRotation(diceRotation + 1);
            }
        }, 100);
        return () => clearTimeout(h);
    }, [diceRotation, setDiceRotation]);

    const itemData: CellData = useMemo(() => ({ searchResult, setQueryInput }), [searchResult, setQueryInput]);

    return (
        <>
            <Container className={classes.inputContainer} fluid>
                <Row>
                    <Col>
                        <Form.Control
                            value={queryInput}
                            autoFocus
                            onChange={(event) => setQueryInput(event.target.value)}
                        />
                    </Col>
                    <Col xs={1} className={classes.diceContainer}>
                        <Button
                            variant="link"
                            onMouseEnter={() => setDiceRotation(1)}
                            onMouseLeave={() => setDiceRotation(0)}
                            onClick={() => setQueryInput(randomWord())}
                        >
                            <DiceIcon rotation={diceRotation} />
                        </Button>
                    </Col>
                    <Col>
                        <RhymeSelect
                            rhymes={queryRhymes}
                            selectedRhyme={selectedRhyme}
                            setSelectedRhyme={setSelectedRhyme}
                        />
                    </Col>
                </Row>
            </Container>
            <Container className={classes.resultContainer} fluid>
                <AutoSizer onResize={() => grid?.resetAfterColumnIndex(0)}>
                    {(props) =>
                        renderCorpusLoader ? (
                            <div style={props}>{renderCorpusLoader()}</div>
                        ) : (
                            <ResultGrid {...props} setGrid={setGrid} itemData={itemData} />
                        )
                    }
                </AutoSizer>
            </Container>
        </>
    );
};

const useStyles = makeStyles()(() => ({
    inputContainer: {
        position: "relative",
        xxTop: "-1rem",
        paddingTop: "1rem",
    },
    resultContainer: {
        flex: 1,
        display: "flex",
        paddingTop: "1rem",
    },
    diceContainer: {
        minWidth: "80px",
        display: "flex",
        alignItems: "center",
    },
}));
