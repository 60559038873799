import React, { FC } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { GridChildComponentProps } from "react-window";
import { Heart, HeartFill } from "react-bootstrap-icons";

import { ResultRecord } from "./schuettel";
import { Items, makePoem, useStore } from "./store";
import { itemParts, toLine } from "./util";
import { makeStyles } from "./styles";
import { QuotedText } from "./QuotedText";

const MAX_WORD_LEN = 10;

const maxLen = (str: string) => (str.length > MAX_WORD_LEN ? str.substring(0, MAX_WORD_LEN) + "..." : str);

const concat = (str1: string, str2: string | undefined) => {
    if (str2 === undefined) {
        return maxLen(str1);
    }
    if (str1.length + str2.length <= MAX_WORD_LEN * 2) {
        return str1 + " " + str2;
    }
    const len1 = str1.length > MAX_WORD_LEN ? MAX_WORD_LEN : Math.min(str1.length, MAX_WORD_LEN * 2 - str2.length);
    const len2 = str2.length > MAX_WORD_LEN ? MAX_WORD_LEN : Math.min(str2.length, MAX_WORD_LEN * 2 - str1.length);
    return str1.substring(0, len1) + "..." + str2.substring(str2.length - len2);
};

const QuotedEditScribble: FC<{ newScribblePlaceholder: string }> = ({ newScribblePlaceholder }) => {
    const { classes } = useStyles();

    const { editScribble } = useStore();

    if (!editScribble) {
        return <>{newScribblePlaceholder}</>;
    }

    const lines = editScribble.scribble.text.split(/\n/);
    const words0 = lines[0].trim().split(/\s+/);
    let result =
        words0.length <= 2
            ? concat(words0[0], words0[1])
            : maxLen(words0[0]) + " ... " + concat(words0[words0.length - 2], words0[words0.length - 1]);
    if (lines.length) {
        const words1 = lines[1].trim().split(/\s+/);
        result += " / ... " + concat(words1[words1.length - 2], words1[words1.length - 1]);
        if (lines.length > 2) {
            result += " / ... ";
        }
    }
    return <QuotedText className={classes.QuotedEditScribble}>{result}</QuotedText>;
};

export interface CellData {
    searchResult: ResultRecord[] | undefined;
    setQueryInput: (query: string) => void;
    twoCols?: boolean;
}

export const Cell: FC<GridChildComponentProps<CellData>> = ({ columnIndex, rowIndex, style, data }) => {
    const { addPoem, removePoem, getPoemByItems, overCell, setOverCell } = useStore();

    const { searchResult, setQueryInput, twoCols } = data;
    const { classes, cx } = useStyles();

    const result = searchResult?.[rowIndex];
    const key = `${columnIndex}:${rowIndex}`;
    const render = (content: React.ReactNode, class_: number) => (
        <span
            key={key}
            style={style}
            className={cx(classes.Cell, {
                [classes.itemCellClass1]: class_ === 1,
                [classes.itemCellClass2]: class_ === 2,
                [classes.itemCellClass3]: class_ === 3,
            })}
        >
            {content}
        </span>
    );

    if (!result) {
        return render("", 3);
    }

    const items = result[0];

    const joinItems = (
        index1: number,
        index2: number,
        resultIndex0: number,
        resultIndex1: number,
        resultIndex2: number,
        resultIndex3: number,
        showFav: boolean
    ) => {
        const resultItems: Items = [items[resultIndex0], items[resultIndex1], items[resultIndex2], items[resultIndex3]];
        const poem = getPoemByItems(resultItems);

        const renderItem = (item: string, withSpace: boolean) => {
            const isOver = overCell && rowIndex === overCell.rowIndex && columnIndex === overCell.columnIndex;
            const className = withSpace ? classes.itemSpace : "";

            const renderLines = (
                resultIndex0: number,
                resultIndex1: number,
                resultIndex2: number,
                resultIndex3: number
            ) => {
                const line1 = toLine(items[resultIndex0], items[resultIndex1]);
                const line2 = toLine(items[resultIndex2], items[resultIndex3]);
                return (
                    <ListGroup.Item action className={classes.addToEditor}>
                        <QuotedText className={classes.addToEditorPoem}>
                            ... {line1}
                            <br />
                            ... {line2}
                        </QuotedText>
                    </ListGroup.Item>
                );
            };

            const renderAppendToEditor = () => (
                <ListGroup.Item className={classes.appendTo}>
                    <div className={classes.appendToTitle}>
                        An <QuotedEditScribble newScribblePlaceholder="neues Gedicht" /> anhängen:
                    </div>
                    <ListGroup className={classes.appendToItems}>
                        {renderLines(resultIndex0, resultIndex1, resultIndex2, resultIndex3)}
                        {renderLines(resultIndex1, resultIndex0, resultIndex3, resultIndex2)}
                        {renderLines(resultIndex2, resultIndex3, resultIndex0, resultIndex1)}
                        {renderLines(resultIndex3, resultIndex2, resultIndex1, resultIndex0)}
                    </ListGroup>
                </ListGroup.Item>
            );

            const renderFav = () => (
                <ListGroup.Item action>
                    {poem ? (
                        <div onClick={() => removePoem(poem)}>
                            <HeartFill className={classes.fav} />
                        </div>
                    ) : (
                        <div onClick={() => addPoem(makePoem(resultItems))}>
                            <Heart className={classes.fav} />
                        </div>
                    )}
                </ListGroup.Item>
            );

            const content = () => {
                if (isOver) {
                    const popover = (
                        <Popover id="popover-basic">
                            <div
                                style={{
                                    position: "absolute",
                                    left: columnIndex >= 2 ? "" : -100,
                                    right: columnIndex >= 2 ? -100 : "",
                                    width: 100,
                                    height: "100%",
                                    backgroundColor: "transparent",
                                }}
                                onClick={(event) => {
                                    setOverCell(undefined);
                                    requestAnimationFrame(() => {
                                        const el = document
                                            .elementFromPoint(event.clientX, event.clientY)
                                            ?.closest(".item");
                                        console.log("Click:", el);
                                        if (el) {
                                            const rowIndex = +(el.getAttribute("data-row_index") ?? 0);
                                            const columnIndex = +(el.getAttribute("data-column_index") ?? 0);
                                            console.log("Click:", rowIndex, columnIndex);
                                            setOverCell({ rowIndex, columnIndex });
                                        }
                                    });
                                }}
                            ></div>
                            <ListGroup variant="flush">
                                <ListGroup.Item action onClick={() => setQueryInput(item)}>
                                    Suche nach "{item}"
                                </ListGroup.Item>
                                {renderAppendToEditor()}
                                {renderFav()}
                            </ListGroup>
                        </Popover>
                    );
                    return (
                        <OverlayTrigger
                            trigger="click"
                            placement={twoCols ? "auto" : columnIndex >= 2 ? "left" : "right"}
                            overlay={popover}
                        >
                            <span>{item}</span>
                        </OverlayTrigger>
                    );
                }
                return item;
            };
            return (
                <span
                    className={cx("item", className)}
                    data-row_index={rowIndex}
                    data-column_index={columnIndex}
                    onMouseEnter={() => setOverCell({ rowIndex, columnIndex })}
                    onMouseLeave={() => setOverCell(undefined)}
                >
                    {content()}
                </span>
            );
        };

        const { item1, item2, isCompound } = itemParts(items[index1], items[index2]);

        const itemNodes = (
            <>
                {showFav && poem ? <HeartFill className={classes.fav} /> : undefined}
                {renderItem(item1, false)}
                {renderItem(item2, !isCompound)}
            </>
        );
        return isCompound ? <span className={classes.compounds}>{itemNodes}</span> : itemNodes;
    };

    const cellContent = () => {
        switch (columnIndex) {
            case 0:
                return joinItems(0, 1, 0, 1, 2, 3, true);
            case 1:
                return joinItems(2, 3, 0, 1, 2, 3, false);
            case 2:
                return null;
            case 3:
                return joinItems(1, 0, 1, 0, 3, 2, true);
            case 4:
                return joinItems(3, 2, 1, 0, 3, 2, false);
            default:
                return "n/a";
        }
    };

    return render(cellContent(), result[1]);
};

const useStyles = makeStyles()(() => ({
    Cell: {
        display: "flex",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingLeft: 10,
        alignItems: "center",
    },
    QuotedEditScribble: {
        display: "inline",
    },
    item: {
        cursor: "pointer",
    },
    itemSpace: {
        marginLeft: "0.5rem",
    },
    itemCellClass1: {
        backgroundColor: "rgba(110, 179, 0, 0.5)",
    },
    itemCellClass2: {
        backgroundColor: "rgba(110, 179, 0, 0.25)",
    },
    itemCellClass3: {
        color: "rgba(0, 0, 0, 0.5)",
    },
    fav: {
        color: "rgb(197, 11, 11)",
        marginRight: "0.5em",
        display: "inline-block",
        transform: "translateY(0.15em)",
        minWidth: "1em",
    },
    compounds: {
        fontWeight: 500,
    },
    addToEditor: {
        display: "flex",
        gap: ".5rem",
        alignItems: "center",
        justifyContent: "end",
    },
    addToEditorPoem: {
        textAlign: "right",
    },
    appendTo: {
        paddingRight: 0,
    },
    appendToTitle: {
        paddingRight: ".75rem",
        paddingBottom: ".5rem",
    },
    appendToItems: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        "& > button": {
            borderRight: 0,
        },
    },
}));
