import { FC, useState, useEffect, createRef, Ref } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { makeScribble, Poem, Scribble, useStore } from "./store";
import { makeStyles } from "./styles";
import { ScribbleNotebook } from "./ScribbleNotebook";
import { ScribbleDefault, ScribbleDefaultProps } from "./ScribbleDefault";
import { ScribbleInlineEditor } from "./ScribbleInlineEditor";
import { ScribbleEditor } from "./ScribbleEditor";
import { QuotedText } from "./QuotedText";

const USE_INLINE_EDITOR = false;

interface ScribblesProps {
    poems: Poem[];
}

export const Scribbles: FC<ScribblesProps> = ({ poems }) => {
    const [scribbleSearch, setScribbleSearch] = useState("");

    const { updateScribble, getScribbles, editScribble, setEditScribble } = useStore();

    const { classes, cx } = useStyles();
    const scribbles = getScribbles({ search: scribbleSearch });

    const arrLength = scribbles.length;
    const [elRefs, setElRefs] = useState<Array<Ref<HTMLDivElement>>>([]);

    useEffect(() => {
        setElRefs((elRefs) =>
            Array<Ref<HTMLDivElement> | null>(arrLength)
                .fill(null)
                .map((_, i) => elRefs[i] ?? createRef<HTMLDivElement>())
        );
    }, [arrLength]);

    const newScribble = () => {
        setEditScribble({ scribble: makeScribble(), isNew: true });
    };

    const onEditScribble = (scribble: Scribble, ref: Ref<HTMLDivElement | null>) => {
        if (ref && "current" in ref && ref.current) {
            // UNUSED
            // console.log(ref.current.offsetWidth, ref.current.offsetHeight);
        }
        setEditScribble({ scribble, isNew: false });
    };

    const onSaveText = (value: string) => {
        if (editScribble) {
            editScribble.scribble.text = value;
            updateScribble(editScribble.scribble);
            setEditScribble(undefined);
        }
    };

    const onCancelEdit = () => {
        setEditScribble(undefined);
    };

    const scribbles_: Scribble[] = editScribble?.isNew ? [editScribble.scribble, ...scribbles] : scribbles;

    if (!USE_INLINE_EDITOR && editScribble) {
        return (
            <div className={classes.ScribblesEditor}>
                <ScribbleEditor scribble={editScribble.scribble} onSave={onSaveText} onCancel={onCancelEdit} />
            </div>
        );
    }

    return (
        <>
            <Container className={classes.ScribblesControls} fluid>
                <Form.Control
                    value={scribbleSearch}
                    autoFocus
                    onChange={(event) => setScribbleSearch(event.target.value)}
                />
                <Button onClick={newScribble}>New</Button>
            </Container>
            <div className={classes.Scribbles}>
                {scribbles_.map((scribble, index) => {
                    const Component: FC<ScribbleDefaultProps> = index & 1 ? ScribbleNotebook : ScribbleDefault;
                    const editor =
                        editScribble && editScribble.scribble.id === scribble.id ? (
                            <ScribbleInlineEditor
                                scribble={editScribble.scribble}
                                onSave={onSaveText}
                                onCancel={onCancelEdit}
                            />
                        ) : undefined;
                    return (
                        <Component
                            key={scribble.id}
                            id={scribble.id}
                            innerRef={elRefs[index]}
                            className={cx(classes.scribbleContainer, editor ? classes.scribbleEditor : undefined)}
                            editor={editor}
                        >
                            <QuotedText onClick={() => onEditScribble(scribble, elRefs[index])}>
                                {scribble.text}
                            </QuotedText>
                        </Component>
                    );
                })}
            </div>
        </>
    );
};

const useStyles = makeStyles<void, "test2">()((theme, _params, classes) => ({
    test2: {},
    test1: {
        [`& .${classes.test2}`]: {
            backgroundColor: "red",
        },
    },
    ScribblesControls: {
        paddingTop: "1rem",
        display: "flex",
    },
    Scribbles: {
        overflowX: "hidden",
        overflowY: "auto",
        paddingBottom: "6rem",
    },
    ScribblesEditor: {
        overflowX: "hidden",
        overflowY: "auto",
        padding: ".5rem",
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    scribbleContainer: {
        marginTop: "1rem",
        marginRight: "var(--bs-gutter-x,.75rem)",
        marginLeft: "var(--bs-gutter-x,.75rem)",
    },
    scribbleEditor: {
        zIndex: 1,
    },
}));
