import * as React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import "./styles.css";
import "./firebase";

import { App } from "./App";

ReactDOM.render(<App />, document.getElementById("app"));
