import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import history from "history/browser";
import queryString from "query-string";

import { useSchuettel } from "./schuettel";
import { Header } from "./Header";
import { useStore } from "./store";
import { Col, Row, Container } from "react-bootstrap";
import { Explorer } from "./Explorer";
import { FavoritePoems } from "./FavoritePoems";
import { Scribbles } from "./Scribbles";
import { makeStyles } from "./styles";
import { useWindowSize } from "./hooks";

export const LANG = "de"; // TODO: Selectable in UI

interface UrlState {
    corpusId: string | undefined;
}

type LeftTab = "explore" | "favpoems" | "mobile-scribbles";
type RightTab = "off" | "favpoems" | "scribbles";

export const App: FC = () => {
    const [corpusId, setCorpusId] = useState("");
    const [leftTab, setLeftTab] = useState<LeftTab>("explore");
    const [rightTab, setRightTab] = useState<RightTab>("off");
    const [rightTabBeforeFavPoem, setRightTabBeforeFavPoem] = useState<RightTab>("off");

    const { classes, cx } = useStyles();
    const { poems } = useStore();
    const { windowWidth } = useWindowSize();

    // @see https://getbootstrap.com/docs/5.0/layout/breakpoints/
    const forMobile = windowWidth < 576;

    const { corpusReady, loadProgress, loadError, runSearch, randomWord, corpusInfos, corpusInfo } =
        useSchuettel(corpusId);

    const getUrlProps = (search: string) => queryString.parse(search) as unknown as UrlState;

    const setStateFromUrl = useCallback((search: string) => {
        const loc = getUrlProps(search);
        if (loc.corpusId) {
            setCorpusId(loc.corpusId);
        }
    }, []);

    useEffect(() => {
        console.log("EFF1");
        if (!forMobile && leftTab === "mobile-scribbles") {
            setLeftTab("explore");
            setRightTab("scribbles");
        } else if (forMobile) {
            setRightTab("off");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forMobile]);

    useEffect(() => setStateFromUrl(history.location.search), [setStateFromUrl]);

    useEffect(
        () =>
            history.listen(({ location, action }) => {
                if (action === "POP") {
                    setStateFromUrl(location.search);
                }
                // console.log("BLOCK", action, location.pathname, location.state, location);
            }),
        [setStateFromUrl]
    );

    useEffect(() => {
        console.log("EFF4");
        if (corpusInfos.length && corpusInfo === undefined) {
            setCorpusId(corpusInfos[0].corpusId);
        }
    }, [corpusInfos, corpusInfo]);

    useEffect(() => {
        console.log("EFF5");
        if (corpusId && getUrlProps(history.location.search).corpusId !== corpusId) {
            history.push("?" + queryString.stringify({ corpusId }));
        }
    }, [corpusId]);

    const renderContent = (content: ReactNode) => (
        <>
            <Header corpusInfos={corpusInfos} corpusId={corpusId} setCorpusId={setCorpusId} />
            {content}
        </>
    );

    if (loadError) {
        return renderContent(
            <div className={classes.loader}>
                :-( {loadError}
                <p>
                    <Button onClick={() => window.location.reload()}>Seite neu laden</Button>
                </p>
            </div>
        );
    }

    const renderCorpusLoader = corpusReady
        ? undefined
        : () => (
              // FIXME I18n
              <div className={classes.loader}>
                  <ProgressBar className={classes.progressBar}>
                      {loadProgress.map((value, index) => (
                          <ProgressBar key={index} animated striped now={(100 * value) / loadProgress.length} />
                      ))}
                  </ProgressBar>
                  <p>Loading "{corpusInfo?.title}"</p>
              </div>
          );

    const setLeftTab_ = (leftTab_: LeftTab) => {
        if (leftTab_ !== leftTab) {
            if (leftTab_ === "favpoems" && rightTab === "favpoems") {
                setRightTab(rightTabBeforeFavPoem);
            }
            setLeftTab(leftTab_);
        }
    };

    const setRightTab_ = (rightTab_: RightTab) => {
        if (rightTab_ !== rightTab) {
            if (rightTab_ === "favpoems") {
                setRightTabBeforeFavPoem(rightTab);
                if (leftTab === "favpoems") {
                    setLeftTab("explore");
                }
            }
            setRightTab(rightTab_);
        }
    };

    const renderTab = <T extends unknown>(id: T, onClick: (id: T) => void, checkId: T, title: string) => (
        <div className={classes.tab} onClick={() => onClick(checkId)}>
            <span className={"nav-link" + (id === checkId ? " active" : "")}>{title}</span>
        </div>
    );

    const renderPane = <T extends unknown, U extends T>(id: T, checkId: U, content: ReactNode) =>
        id === checkId ? <div className={classes.tabPane}>{content}</div> : undefined;

    const tabs = forMobile ? (
        <Col className="nav nav-tabs">
            {renderTab(leftTab, setLeftTab_, "explore", "Entdecken")}
            {renderTab(leftTab, setLeftTab_, "favpoems", "Favoriten")}
            {renderTab(leftTab, setLeftTab_, "mobile-scribbles", "Meine Sachen")}
        </Col>
    ) : (
        <Col className="nav nav-tabs">
            {renderTab(leftTab, setLeftTab_, "explore", "Entdecken")}
            {renderTab(leftTab, setLeftTab_, "favpoems", "Favoriten")}
            <div className={classes.tabSpacer} />
            {renderTab(rightTab, setRightTab_, "favpoems", "Favoriten")}
            {renderTab(rightTab, setRightTab_, "scribbles", "Meine Sachen")}
            {renderTab(rightTab, setRightTab_, "off", "X")}
        </Col>
    );

    return renderContent(
        <>
            <Container fluid className={cx(classes.tabs, "bg-light")}>
                <Row className={classes.tabsInner}>{tabs}</Row>
            </Container>
            <div className={classes.tabContent}>
                {renderPane(
                    leftTab,
                    "explore",
                    <Explorer
                        renderCorpusLoader={renderCorpusLoader}
                        corpusId={corpusId}
                        poems={poems}
                        runSearch={runSearch}
                        randomWord={randomWord}
                    />
                )}
                {renderPane(
                    leftTab,
                    "favpoems",
                    <FavoritePoems poems={poems} side={rightTab === "off" ? "right" : "left"} />
                )}
                {renderPane(leftTab, "mobile-scribbles", <Scribbles poems={poems} />)}
                {renderPane(rightTab, "favpoems", <FavoritePoems poems={poems} side="right" />)}
                {renderPane(rightTab, "scribbles", <Scribbles poems={poems} />)}
            </div>
        </>
    );
};

const useStyles = makeStyles()(() => ({
    loader: {
        display: "flex",
        alignItems: "center",
        height: "inherit",
        margin: 100,
        flexDirection: "column",
    },
    progressBar: {
        width: "100%",
    },
    tabContent: {
        flex: 1,
        display: "flex",
        overflow: "hidden",
    },
    tabs: {
        borderTop: "1px solid #dee3e7",
        paddingTop: ".5rem",

        "& .nav-tabs": {
            flexWrap: "nowrap",
        },

        "& .nav-link": {
            overflow: "hidden",
            textOverflow: "ellipsis",
        },

        "& .nav-link.active": {
            backgroundColor: "#eee",
            borderColor: "#96a3b0 #96a3b0 #eee",
        },
    },
    tabsInner: {
        paddingLeft: ".75em",
    },
    tabSpacer: {
        flex: 1,
    },
    tab: {
        cursor: "pointer",
        overflow: "hidden",
        whiteSpace: "nowrap",
        position: "relative",
        top: 1,
    },
    tabPane: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
}));
