import { useState, useEffect, useRef } from "react";

const scrollbarWidth = () => {
    // From:
    // https://github.com/bvaughn/react-window/blob/master/src/domHelpers.js
    // Not exposed by react-window :-(
    const div = document.createElement("div");
    const style = div.style;
    style.width = "50px";
    style.height = "50px";
    style.overflow = "scroll";
    document.body.appendChild(div);
    const size = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);
    return size;
};

export const useScrollbarWidth = (): number => {
    const [width] = useState(scrollbarWidth());
    return width;
};

export function useWindowSize() {
    const [size, setSize] = useState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
    });

    useEffect(() => {
        const onSizeChange = () => setSize({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
        window.addEventListener("resize", onSizeChange);
        return () => {
            window.removeEventListener("resize", onSizeChange);
        };
    }, []);

    return size;
}

// https://github.com/alibaba/hooks/blob/master/packages/hooks/src/createUpdateEffect/index.ts
export const useUpdateEffect: typeof useEffect = (effect, deps) => {
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            return effect();
        }
        isMounted.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
