import React, { FC } from "react";
import Select, { SingleValue } from "react-select";

interface RhymeOption {
    value: string;
    label: string;
}
interface RhymeSelectProps {
    rhymes: string[];
    selectedRhyme: string;
    setSelectedRhyme: (rhyme: string) => void;
}
export const RhymeSelect: FC<RhymeSelectProps> = ({ rhymes, selectedRhyme, setSelectedRhyme }) => {
    const options = React.useMemo(() => rhymes.map((value) => ({ value, label: value })), [rhymes]);

    const onChange = (option: SingleValue<RhymeOption>): void => {
        setSelectedRhyme(option?.value ?? "");
    };

    const selectedRhymeOption: RhymeOption = React.useMemo(
        () => ({ value: selectedRhyme, label: selectedRhyme }),
        [selectedRhyme]
    );

    return (
        <Select<RhymeOption>
            value={selectedRhymeOption}
            name="value"
            options={options}
            isClearable={true}
            backspaceRemovesValue={true}
            onChange={onChange}
        />
    );
};
