import React, { FC, useMemo } from "react";
import { VariableSizeGrid as Grid } from "react-window";

import { useScrollbarWidth } from "./hooks";
import { CellData, Cell } from "./Cell";
import { makeStyles, important } from "./styles";

interface ResultGridProps {
    width: number;
    height: number;
    setGrid: React.Dispatch<React.SetStateAction<Grid<any> | null>>;
    itemData: CellData;
}

export const ResultGrid: FC<ResultGridProps> = ({ width, height, setGrid, itemData }) => {
    const scrollbarWidth = useScrollbarWidth();
    const { classes } = useStyles();

    const innerWidth = width - scrollbarWidth - 2; // minus border width
    const twoCols = innerWidth < 400;

    const colLeft = (index: number) => {
        if (twoCols) {
            return Math.floor((index * innerWidth) / 2);
        }
        const colScale = 4;
        const w = 4 * colScale + 1;
        const x = index > 2 ? (index - 1) * colScale + 1 : index * colScale;
        return Math.floor((innerWidth * x) / w);
    };

    const itemData2 = useMemo(() => ({ ...itemData, twoCols }), [itemData, twoCols]);

    return (
        <Grid<CellData>
            className={classes.grid}
            ref={setGrid}
            columnWidth={(index) => colLeft(index + 1) - colLeft(index)}
            columnCount={twoCols ? 2 : 5}
            height={height}
            rowCount={itemData2.searchResult?.length ?? 0}
            width={width}
            rowHeight={() => 30}
            itemData={itemData2}
        >
            {Cell}
        </Grid>
    );
};

const useStyles = makeStyles()(() => ({
    grid: {
        backgroundColor: "#fff",
        border: "1px solid #ced4da",
        overflowY: important("scroll"),
    },
}));
