import React, { FC, ReactNode, Ref } from "react";
import Card from "react-bootstrap/Card";

import { makeStyles } from "./styles";

export interface ScribbleDefaultProps {
    id: string;
    innerRef: Ref<HTMLDivElement>;
    className: string;
    editor: ReactNode;
}

export const ScribbleDefault: FC<ScribbleDefaultProps> = ({ innerRef, className, editor, children }) => {
    const { classes, cx } = useStyles();

    return (
        <Card ref={innerRef} className={cx(className, classes.ScribbleDefault)}>
            {editor}
            <Card.Body>{children}</Card.Body>
        </Card>
    );
};

const useStyles = makeStyles()(() => ({
    ScribbleDefault: {
        position: "relative",
    },
}));
