import { FC } from "react";

import { makeStyles } from "./styles";

interface QuotedTextProps {
    className?: string;
    onClick?: () => void;
}

export const QuotedText: FC<QuotedTextProps> = ({ className, children, onClick }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(className, classes.QuotedText)} onClick={onClick}>
            {children}
        </div>
    );
};

const useStyles = makeStyles()((theme) => ({
    QuotedText: {
        whiteSpace: "pre-wrap",
        textAlign: "right",
        margin: 0,
        fontSize: "inherit",

        "&:before": {
            content: "open-quote",
        },
        "&:after": {
            content: "close-quote",
            marginLeft: "-0.1em",
        },
        "&:before, &:after": {
            display: "inline-block",
            verticalAlign: "bottom",
            top: "0",
            position: "relative",
            padding: "0.1em",
        },
    },
}));
